<template>
  <div class="homepage">
    <Goodbye />
  </div>
</template>

<style scoped lang="scss">
  .homepage {
    display: flex;
    flex-direction: column;
  }
</style>
