<script setup lang="ts">
  const REDIRECT_TIME = 10000; //10 seconds

  onMounted(() => {
    setTimeout(() => {
      navigateTo('https://mojposao.hr/', { external: true });
    }, REDIRECT_TIME);
  });
</script>

<template>
  <div class="notification">
    <p>Poštovani korisnici,</p>
    <p>
      <b>
        Želimo vas obavijestiti da je naš portal
        <a src="https://poslovac.hr/">poslovac.hr</a> prestao s radom te usluga više nije dostupna.
      </b>
    </p>
    <p>
      Zahvaljujemo vam na korištenju našeg portala i želimo vam puno uspjeha u vašim budućim
      profesionalnim poduhvatima.
    </p>
    <p>
      Ponudu poslova možete pogledati na portalu
      <a
        class="mp"
        src="https://mojposao.hr/"
        >mojposao.hr</a
      >
    </p>
    <p>
      Uskoro ćete biti preusmjereni na
      <a
        class="mp"
        src="https://mojposao.hr/"
        >mojposao.hr</a
      >
    </p>
  </div>
</template>

<style lang="scss">
  .page {
    &__content {
      min-height: 200px !important;
      max-width: 800px !important;
    }
  }

  .header {
    max-width: 800px !important;
    &__top-right {
      display: none !important;
    }
    &__left-sidebar-container {
      display: none !important;
    }
  }

  footer {
    display: none !important;
  }
</style>

<style scoped lang="scss">
  .notification {
    font-weight: 400;
    font-size: 18px;

    b {
      font-weight: 600;
    }

    a {
      cursor: pointer;

      &.mp {
        font-weight: 600;
        color: #ff4a40;
      }
    }
  }
</style>
